import * as React from "react"
import { useMatch } from "@reach/router"
import GuideDetailsPage from '@components/GuideDetails/GuideDetails'

import { GUIDE_PAGE_URL } from "@components/common/site/constants";

const GuideDetails = () => {

  const GuideDetails = useMatch(GUIDE_PAGE_URL.alias + "/:slug")
  var URL = GuideDetails.slug
  if (GuideDetails !== null) {
    var URL = GuideDetails.slug
    return (
      <GuideDetailsPage slug={URL} />
    )
  } 

}


export default GuideDetails
